<template>
  <v-container
    grid-list-xs
    fluid
    class="pa-2 pt-0 pb-0"
  >
    <v-container
      grid-list-xs
      fluid
      class="pa-2 pt-0 pb-0"
      v-if="anytimeConsultations"
    >
      <v-card
        :flat="true"
        class="anytime-consultation-search-info pl-2 pt-2"
      >
        <v-card-text class="row pa-0 d-flex flex-row">
          {{ order }}に表示 / {{ anytimeConsultations.length }}件ヒット
          <span
            class="ml-auto"
            v-if="incompleted">
            <v-switch
              v-model="filtered"
              base-color="grey-lighten-1"
              color="blue-accent-1"
              hide-details
              :flat="true"
              label="担当分を表示"
              class="ma-0 filtered-toggle"
              :class="anytimeConsultationFilteredFlag? 'filtered' : 'unfiltered'"
              @change="switchFilterdFlag"
            >
            </v-switch>
          </span>
        </v-card-text>
      </v-card>
      <v-container fluid>
        <div
          v-for="consultation in anytimeConsultations"
          :key="consultation.id"
          class="anytime-consultation-info"
          :class="showIncentiveNotice(consultation) ? 'over-deadline-incentive' : ''"
        >
          <div class="incentive-notice ma-2" v-if="showIncentiveNotice(consultation)">
            <v-icon
              class="notice-icon"
              color="white"
              size="30px"
            >mdi-alert-circle-outline</v-icon>
            他の相談員が期限内に回答できませんでした。代わりに回答をお願いいたします。インセンティブをお支払いします。
          </div>
          <anytimeConsultationDetail
            :anytimeConsultation = "consultation"
            :tabId="tabId"
            :incentive="showIncentiveNotice(consultation)"
          />
        </div>

        <!-- 担当分を表示 -->
        <!-- 未回答 -->
        <!-- 担当科目 -->
        <div v-if="anytimeConsultationFilteredFlag && incompleted && currentDepartmentType">
          <!-- 受付時間中 -->
          <div v-if="onShift">
            <anytimeConsultationAnswerNotice
              bgColor="#F9E5EC"
              textColor="#E00051"
              icon="mdi-alert-circle-outline"
              title="受付時間中"
              detail="相談が増える可能性があります。"
            >
            </anytimeConsultationAnswerNotice>
          </div>
          <!-- 受付終了 -->
          <div v-else-if="anytimeConsultations.length > 0">
            <anytimeConsultationAnswerNotice
              bgColor="#D7D7D7"
              textColor="#8A8A8A"
              icon="mdi-bell-outline"
              title="受付終了"
              detail="これ以上相談は増えません。"
            >
            </anytimeConsultationAnswerNotice>
          </div>
          <!-- 勤務完了 -->
          <div v-else-if="noticeExpiration">
            <anytimeConsultationAnswerNotice
              bgColor="#ECF7E4"
              textColor="#69B72E"
              icon="mdi-emoticon-happy-outline"
              title="勤務完了"
              detail="ご担当ありがとうございました。"
            >
            </anytimeConsultationAnswerNotice>
          </div>
        </div>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AnytimeConsultationDetail from "./shared/AnytimeConsultationDetailCard.vue";
import AnytimeConsultationAnswerNotice from "./shared/ac_shift/AnytimeConsultationAnswerNotice.vue";

export default {
  name: "AnytimeConsultationList",
  components: {
    AnytimeConsultationDetail,
    AnytimeConsultationAnswerNotice
  },
  data: () => ({
    tabId: 0,
    order: "古い順",
    incompleted: true,
    departmentType: null,
    filtered: true,
    dapartmentTypes: {
      "1": "pediatrician",
      "2": "obstetrician",
      "3": "midwife",
    },
    currentDepartmentType: false,
  }),
  computed: {
    ...mapGetters([
      "anytimeConsultations",
      "currentDoctor",
      "anytimeConsultationFilteredFlag",
      "onShift",
      "noticeExpiration"
    ]),
    onDutyDoctor: function () {
      return this.currentDoctor && this.currentDoctor.type == "Onduty::Doctor";
    },
    operatorDoctor: function () {
      return this.currentDoctor && this.currentDoctor.type == "Operator::Doctor";
    },
  },
  methods: {
    ...mapActions([
      "fetchAnytimeConsultations",
      "fetchAssignedAnytimeConsultations",
      "fetchCompletedAnytimeConsultations",
      "assignFilteredFlag",
      "fetchCurrentDoctor",
      "isOnAnytimeConsultationShift",
      "isAnswerNoticeExpiration"
    ]),
    loadList: function () {
      this.incompleted = this.$route.query.complete == 0 ? true : false
      this.departmentType = this.$route.query.department;
      this.currentDepartmentType = (this.departmentType in this.dapartmentTypes && this.dapartmentTypes[this.departmentType] === this.currentDoctor.department_type);
      if(this.incompleted){
        this.loadIncompletedList()
      } else {
        // 完了済
        this.fetchCompletedAnytimeConsultations({
          department: this.departmentType
        });
        this.order = "新しい順";
      }
      this.tabId = this.$route.query.tab_id;
    },
    loadIncompletedList: function() {
      // 未完了
      if((this.onDutyDoctor || this.operatorDoctor ) && this.filtered){
        // アサインされた相談のみ
        this.fetchAssignedAnytimeConsultations({
          department: this.departmentType
        });
      } else {
        // すべての相談
        this.fetchAnytimeConsultations({
          department: this.departmentType
        });
      }
      this.order = "古い順";
    },
    switchFilterdFlag: function() {
      this.assignFilteredFlag(this.filtered)
      this.loadIncompletedList()
    },
    isOnShift: function () {
      this.isOnAnytimeConsultationShift({
        doctor_id: this.currentDoctor.id
      });
    },
    isNoticeExpiration: function () {
      this.isAnswerNoticeExpiration({
        doctor_id: this.currentDoctor.id
      });
    },
    showIncentiveNotice: function (consultation) {
      return this.filtered && this.incompleted && consultation.overDeadline
    }
  },
  watch: {
    "$route.query" () {
      this.loadList();
      this.isOnShift();
      this.isNoticeExpiration();
    }
  },
  created() {
    this.fetchCurrentDoctor().then(() => {
      if(this.onDutyDoctor || this.operatorDoctor){
        this.filtered = this.anytimeConsultationFilteredFlag;
      } else {
        this.filtered = false
      }
      this.loadList();
      this.isOnShift();
      this.isNoticeExpiration();
    });
  },
};
</script>

<style lang="scss">
  .anytime-consultation-search-info {
    background-color: #fafafa;
    margin: 2px;

    .v-card__text {
      background-color: #fafafa;
    }
  }

  .anytime-consultation-info {
    border: solid 1px #888888;
    background-color: white;
    border-radius: 2px;

    .v-card {
      border: solid 1px #bdbdbd !important;
      height: 100%;
    }
  }
  .over-deadline-incentive{
    background-color: #E00051!important;

    div.incentive-notice {
      font-weight: bold;
      font-size: 14px;
      color: white;

      .notice-icon {
        width: 30px;
        height: 30px;
      }
    }
  }
  .filtered {
    label {
      color: #212121 !important;
    }
  }
  .unfiltered {
    label {
      color: #ccc !important;
    }
  }
  .v-switch .v-selection-control {
    min-height: 0px !important;
  }
  .v-switch .v-selection-control--density-default {
    --v-selection-control-size: auto !important;
  }
  .v-switch .v-selection-control > label {
    margin-bottom: 0px !important;
  }
</style>
