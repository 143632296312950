<template>
  <div class="l-anytime-consultation">
    <div class="l-anytime-consultation__main flex-grow-1">
      <v-btn
        :to="{ path: '/anytime_consultations', query: { tab_id: this.tabId } }"
        variant="text"
        color="pink"
      >
        一覧へ戻る
      </v-btn>

      <!-- 事前問診 -->
      <anytime-consultation-inquiry :class="{'mb-4': true,'pa-0': true, 'px-6': true, 'is-minimum': isSearchbarMaximum }" />
      <!--/ 事前問診 -->

      <!-- 関連情報 -->
      <template v-if="!isSearchbarMaximum">
        <recommend-journals ref="recommend-journals" />
      </template>
      <!--// 関連情報 -->

      <div class="l-anytime-consultation-answer d-flex flex-column flex-sm-row pa-0 pa-sm-6">
        <div :class="{'l-anytime-consultation-answer__left': true, 'w0': isSearchbarMaximum }">
          <!-- サイドバー -->
          <anytime-consultation-addon
            v-if="consultation.userId != null"
            ref="addon"
            :active="answer.editable"
            :user-id="consultation.userId"
            :child-id="consultation.childId"
          />
          <!--/ サイドバー -->
        </div>
        <div class="l-anytime-consultation-answer__right pa-0 ma-sm-2">
          <div class="pa-6 p-answer-form">
            <div>
              <h3 style="color:#D63864; font-size: 1.17em; font-weight: bold;">
                相談員記入欄
              </h3>
              <div class="pt-2">
                <b>回答状況</b>
                <span class="pr-3">： {{ answer.status }}</span>
              </div>
              <div>
                <b>更新日時</b>
                <span class="pr-3">： {{ answer.updatedAt }}</span>
              </div>
              <div>
                <b>記入者</b>
                <span class="pr-3">： {{ answer.doctorName }}</span>
              </div>
              <div>
                <b>送信日時</b>
                <span class="pr-3">： {{ answer.sentAt }}</span>
              </div>
            </div>
            <!-- 回答記入 -->
            <div class="pt-2">
              <v-textarea
                id="input-complaint"
                v-model="answer.complaint"
                variant="outlined"
                hide-details
                name="complaint"
                label="主訴（必須）*"
                rows="2"
                :readonly="!answer.editable"
                required
                @focus="isComplaintFocus = true"
                @blur="isComplaintFocus = false"
              />
              <div class="mb-5">
                <div
                  v-if="answer.editable && isComplaintFocus"
                  style="color: #FB0606;"
                >
                  ■非医療者にもわかる言葉で書いてください。<br>
                  ■個人を特定できる情報は入力しないでください（妊娠週数、月齢など）<br>
                  ※自治体・企業への共有、相談事例として公開することがあるため<br>
                </div>
              </div>
              <midwife-diagnosis
                v-if="consultation.department_type === 'midwife'"
                v-model:karte="answer"
                :active="answer.editable"
              />
              <diagnosis
                v-else
                v-model:karte="answer"
                :department-data="consultation.department_type"
                :active="answer.editable"
              />
              <v-textarea
                id="input-answer-content"
                v-model="answer.content"
                variant="outlined"
                name="answer-content"
                label="回答（必須）*"
                rows="18"
                :readonly="!answer.editable"
                required
                class="linefeed-label answer-input"
                hide-details
              />
              <div>
                <span>{{ this.answerContentLength }}文字</span>
                <span v-if="isEnoughContent">
                  <v-icon
                    color="green"
                    style="font-size: 1em"
                  >
                    check_circle
                  </v-icon>
                </span>
                <span v-else>
                  （推奨文字数まで {{ 700 - this.answerContentLength }} 文字）
                </span>
              </div>
              <!-- カルテ情報 相談内容報告欄 -->
              <div
                v-if="consultation.cooperateWithClinic && answer.forReporting"
                class="cooperated-area"
              >
                <h3
                  style="color: #D63864; font-size: 1.17em; font-weight: bold;"
                  class="text-center"
                >
                  相談内容報告欄
                </h3>
                <div>
                  この相談はかかりつけクリニックに報告する必要があります。<br>
                  事前問診のボックス内（氏名・生年月日など）と以下の3つの欄に入力の内容をクリニックの医師が閲覧します。<br>
                  相談内容報告欄の記載をお願いします。
                </div>
                <v-btn
                  :disabled="!answer.editable"
                  style="background-color: #ec407a; border-color: #ec407a;"
                  @click="copyFromKarteFields()"
                >
                  カルテ内容を反映する
                  <v-icon icon="mdi-content-copy" />
                </v-btn>

                <v-textarea
                  v-model="answer.forReporting.complaint"
                  variant="outlined"
                  hide-details
                  name="complaint_for_reporting"
                  label="報告用主訴（必須）*"
                  required
                  rows="2"
                  :readonly="!answer.editable"
                  :disabled="!answer.editable"
                  class="mb-4"
                />

                <v-textarea
                  v-model="answer.forReporting.diagnosis"
                  variant="outlined"
                  hide-details
                  name="diagnosis_for_reporting"
                  label="報告用暫定診断（必須）*"
                  required
                  rows="2"
                  :readonly="!answer.editable"
                  :disabled="!answer.editable"
                  class="mb-4"
                />

                <v-textarea
                  v-model="answer.forReporting.content"
                  variant="outlined"
                  name="answer-content_for_reporting"
                  label="報告用回答（必須）*"
                  required
                  rows="18"
                  counter
                  :readonly="!answer.editable"
                  :disabled="!answer.editable"
                  class="linefeed-label"
                />
              </div>
              <!--// カルテ情報 相談内容報告欄 -->

              <div class="p-btn-area d-flex justify-end flex-wrap">
                <template v-if="answer.editable">
                  <v-tooltip
                    v-model="saveDraftMessageShow"
                    location="top"
                    text="Tooltip"
                    :open-on-click="false"
                    :open-on-hover="false"
                    :close-delay="2000"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="saveDraftBtn mt-2 mx-0"
                        variant="outlined"
                        color="pink"
                        @click="saveDraft();"
                      >
                        下書き保存
                      </v-btn>
                    </template>
                    <span class="d-flex saveDraftMessage"><v-icon>mdi-check</v-icon>下書きに保存されました</span>
                  </v-tooltip>
                  <div class="d-flex ml-8">
                    <div class="requiredTimeSelect">
                      <v-select
                        v-model="answer.requiredTime"
                        :items="requiredTimeLabels"
                        item-title="text"
                        item-value="value"
                        required
                        density="compact"
                        variant="solo-filled"
                        hide-details="false"
                      />
                    </div>
                    <v-btn
                      class="sendBtn"
                      style="margin-left: 1px!important;"
                      color="pink"
                      tile
                      @click="completeAnswer()"
                    >
                      送信予約
                    </v-btn>
                  </div>
                </template>
                <template v-else-if="answer.sentAt == null">
                  <div class="requiredTimeSelect">
                    <v-select
                      v-model="answer.requiredTime"
                      :items="requiredTimeLabels"
                      item-title="text"
                      item-value="value"
                      disabled
                      density="compact"
                      variant="solo-filled"
                      hide-details="false"
                    />
                  </div>
                  <v-btn
                    color="pink lighten-1"
                    style="margin-left: 1px!important;"
                    tile
                    variant="outlined"
                    @click="cancelAnswer()"
                  >
                    送信予約取り消し
                  </v-btn>
                </template>
                <template v-else>
                  <div class="requiredTimeSelect">
                    <v-select
                      v-model="answer.requiredTime"
                      :items="requiredTimeLabels"
                      item-title="text"
                      item-value="value"
                      disabled
                      density="compact"
                      variant="solo-filled"
                      hide-details
                    />
                  </div>
                </template>
              </div>
            </div>
          </div>
          <!-- お知らせ欄 -->
          <div class="mt-8">
            <p class="text-md-center text-pink">
              ※ 運営からのお知らせ ※
            </p>
            <div class="px-6">
              <p
                style="white-space: pre-wrap; line-height: 25px; word-break: break-all;"
                v-html="referenceInfo.content"
              />
            </div>
          </div>
          <!--/ お知らせ欄 -->
          <!-- 内容クリア -->
          <div
            v-if="!!answer.id && answer.editable"
            class="text-center"
          >
            <v-btn
              color="pink"
              style="text-decoration: underline;"
              variant="text"
              @click="deleteAnswer()"
            >
              このカルテの内容をクリアする
            </v-btn>
          </div>
          <!--/ 内容クリア -->
        </div>
      </div>
    </div>
    <anytime-consultation-search-bar
      v-if="answer.editable"
      class="l-anytime-consultation__searchbar flex-grow-0 d-none d-sm-flex"
      :width-rank="widthRank"
      @updateWidthRank="updateWidthRank"
    />
    <template>
      <!-- Error Message -->
      <v-dialog
        v-model="dialog"
        max-width="500"
      >
        <v-card>
          <v-card-text
            v-html="$sanitize(newLineCodeToBr(popupMessage))"
          />
          <v-card-actions>
            <v-btn
              class="ms-auto"
              text="閉じる"
              variant="tonal"
              @click="dialog = false"
            />
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Diagnosis from "./shared/Diagnosis.vue";
import MidwifeDiagnosis from "./shared/MidwifeDiagnosis.vue";
import RecommendJournals from "./shared/RecommendJournals.vue";
import AnytimeConsultationInquiry from "./shared/AnytimeConsultationInquiry.vue";
import AnytimeConsultationAddon from "./shared/AnytimeConsultationAddon.vue";
import AnytimeConsultationSearchBar from "./shared/AnytimeConsultationSearchBar.vue";
// 改行ToBr
import commonMixin from "../mixins/chat_log_common.js";

export default {
  name: "AnytimeConsultation",
  components: {
    Diagnosis,
    MidwifeDiagnosis,
    RecommendJournals,
    AnytimeConsultationInquiry,
    AnytimeConsultationAddon,
    AnytimeConsultationSearchBar
  },
  mixins: [commonMixin],
  data: () => ({
    tabId: 0,
    widthRank: 2,
    isComplaintFocus: false,
    requiredTimeLabels: [
      { text: "回答の作成時間", value: null },
      { text: "5分未満", value: "less_5" },
      { text: "5分以上10分未満",  value: "between_5_10" },
      { text: "10分以上20分未満", value: "between_10_20" },
      { text: "20分以上30分未満", value: "between_20_30" },
      { text: "30分以上40分未満", value: "between_30_40" },
      { text: "40分以上50分未満", value: "between_40_50" },
      { text: "50分以上60分未満", value: "between_50_60" },
      { text: "60分以上", value: "more_60" },
    ],
    // 下書き保存ボタン
    saveDraftMessageShow: false,
    showDraftMessageTimer: null,
    // 注意文言を表示するダイアログ
    dialog: false
  }),
  computed: {
    ...mapGetters({
      consultation: "anytimeConsultation",
      answer: "anytimeConsultationAnswer",
      referenceInfo: "anytimeConsultationReferenceInfo",
      recommendJournals: "recommendJournals",
      popupMessage: "popupMessage"
    }),
    isAnswerContentFilled: function() {
      return !!this.answer.complaint && (!!this.answer.branch_id || !!this.answer.original_disease) && !!this.answer.content;
    },
    isRequiredTimeFilled: function() {
      return !!this.answer.requiredTime;
    },
    isContainedDraftMessage: function() {
      return this.answer.content.match(/「ーーーーーーーーーーーー」/) || this.answer.content.match(/（回答本文）/);
    },
    answerContentLength: function () {
      if (this.answer === null || this.answer.content === null || typeof this.answer.content === "undefined") {
        return 0;
      }
      return this.answer.content.length;
    },
    isEnoughContent: function () {
      return this.answerContentLength >= 700;
    },
    isSearchbarMaximum: function () {
      return this.widthRank == 3;
    }
  },
  created() {
    // 一旦モデルを空にする
    this.clearEpdsAnswers();
    this.$store.dispatch("clearAnytimeConsultationObjects");

    this.$store.dispatch("findAnytimeConsultation", {
      id: this.$route.params.id
    });
    this.$store.dispatch("findAnytimeConsultationAnswer", {
      anytime_consultation_id: this.$route.params.id
    });
    this.$store.dispatch("findAnytimeConsultationReferenceInfo", {
      anytime_consultation_id: this.$route.params.id
    });
    this.$store.dispatch("searchRecommendJournals", {
      anytimeConsultationId: this.$route.params.id
    });
    this.tabId = this.$route.query.tab_id;
  },
  methods: {
    ...mapActions([
      "updateUser",
      "updateChild",
      "clearEpdsAnswers"
    ]),
    saveDraft: function() {
      this.$store.dispatch("saveAnytimeConsultationAnswerDraft", {
        anytime_consultation_id: this.consultation.id,
        content: this.answer.content,
        complaint: this.answer.complaint,
        icd10_branch_id: this.answer.branch_id,
        icd10_original_disease: this.answer.original_disease,
        required_time: this.answer.requiredTime
      }).then(() => {
        // 更新成功したら他の要素も保存する
        this.updateUser(this.$refs.addon.user);
        if(this.$refs.addon.child.id){
          // 子情報が存在するときだけ更新する
          this.updateChild(this.$refs.addon.child);
        }
        clearTimeout(this.showDraftMessageTimer);
        this.saveDraftMessageShow = true;
        this.showDraftMessageTimer = setTimeout(() => {
          this.saveDraftMessageShow = false;
        }, 2000);
      }).catch(() => {
        // 更新失敗
        if(this.popupMessage){
          this.dialog = true;
        }
        // 最新を再取得 - 入力内容はクリアされて良い
        this.$store.dispatch("findAnytimeConsultationAnswer", {
          anytime_consultation_id: this.$route.params.id
        });
      });
    },
    completeAnswer: function() {
      if(!this.isAnswerContentFilled){
        return alert("記入欄に入力漏れがあります");
      }
      if(!this.isRequiredTimeFilled){
        return alert("「回答の作成時間」を選択してください");
      }
      if(this.isContainedDraftMessage){
        return alert("回答文にテンプレート文章が残っています");
      }

      this.$store.dispatch("completeAnytimeConsultationAnswer", {
        anytime_consultation_id: this.consultation.id,
        content: this.answer.content,
        complaint: this.answer.complaint,
        icd10_branch_id: this.answer.branch_id,
        icd10_original_disease: this.answer.original_disease,
        required_time: this.answer.requiredTime
      }).then(() => {
        // 送信予約成功
        this.$store.dispatch("findAnytimeConsultationReferenceInfo", {
          anytime_consultation_id: this.$route.params.id
        });
        this.tabId = this.$route.query.tab_id;
        this.updateUser(this.$refs.addon.user);
        if(this.$refs.addon.child.id){
          // 子情報が存在するときだけ更新する
          this.updateChild(this.$refs.addon.child);
        }
      }).catch(() => {
        // 更新失敗
        if(this.popupMessage){
          this.dialog = true;
        }
        // 最新を再取得 - 入力内容はクリアされて良い
        this.$store.dispatch("findAnytimeConsultationAnswer", {
          anytime_consultation_id: this.$route.params.id
        });
      });
    },
    cancelAnswer: function() {
      this.$store.dispatch("cancelAnytimeConsultationAnswer", {
        anytime_consultation_id: this.consultation.id,
      }).then(() => {
        // なにもしない
      }).catch(() => {
        // 更新失敗
        if(this.popupMessage){
          this.dialog = true;
        }
        // 最新を再取得
        this.$store.dispatch("findAnytimeConsultationAnswer", {
          anytime_consultation_id: this.$route.params.id
        });
      });
    },
    deleteAnswer: function() {
      if(confirm("入力された内容を全てクリアします。本当によろしいですか？")){
        this.$store.dispatch("deleteAnytimeConsultationAnswer", {
          anytime_consultation_id: this.consultation.id
        }).then(() => {
        // なにもしない
        }).catch(() => {
          // 更新失敗
          // 現状ではメッセージの表示は行っていない
        });
      }
    },
    updateWidthRank: function (widthRank) {
      this.widthRank = widthRank;
    }
  }
};
</script>

<style lang="scss">

.l-anytime-consultation {
  display: flex;
  overflow: auto;
  height: 100vh !important;
  width: 100%;
  font-size: 14px;

  &__main{
    @media (min-width: 600px) {
      max-height: 100vh;
      overflow: scroll;
    }
  }
  // &__searchbar{}
}

.l-anytime-consultation-answer{
  &__left{
    width: 360px;
    transition: width 0.3s 0s ease;
    @media (max-width: 600px) {
      width: 100%;
    }
    &.w0{
      width: 0px;
    }
  }
  &__right{
    width: 100%;
  }

  .v-text-field--outlined:not(.v-input--is-focused),
  .v-text-field--outlined:not(.v-input--is-focused) .v-input__slot:hover {
    border: 2px solid rgba(0, 0, 0, .54);
  }

  // 入力欄の背景を薄青色にする
  .anytime-consultation-addon {
    .v-input.v-text-field:not(.v-input--disabled) .v-field__field {
      background-color: #e8f2ff !important;
    }
  }
  .diagnosis-form {
    .v-input.v-text-field:not(.v-input--disabled) .v-field__field {
      background-color: #e8f2ff !important;
    }
  }
  .v-input.v-text-field:not(.v-input--readonly) .v-field__field {
    background-color: #e8f2ff !important;
  }
  .parent-special-note .v-input.v-text-field:not(.v-input--disabled) {
    background-color: #e8f2ff !important;
  }
  .child-special-note .v-input.v-text-field:not(.v-input--disabled) {
    background-color: #e8f2ff !important;
  }

  /* vuetify3 対応 入力不可のラベルの透明度を1にする */
  .v-field--disabled {
    --v-disabled-opacity: 1 !important;
  }

  .p-btn-area {
    .requiredTimeSelect {
      max-width: 200px;

      .v-field--active {
        min-height: 36px !important;
        margin-top: 6px;
        border-radius: 0px !important;
        color: #fff !important;
        background-color: #EF5A8D !important;
        .v-field__field {
          background-color: #EF5A8D !important;
        }
      }

      .theme--light.v-icon {
        font-size: 14px;
        color: #fff !important;
      }

      .theme--light.v-label {
        font-size: 14px;
        color: #fff !important;
      }
      .v-select__selection-text {
        font-size: 14px;
        color: #fff;
      }

      .mdi-menu-down::before{
        font-size: 1.17em
      }
    }

    .saveDraftBtn{
      background: #FFFFFF;
      border: 1px solid #EF1A65;
      .v-btn__content .text-black {
        color: #EF1A65;
      }
    }
    .sendBtn {
      .v-btn__content {
        color: #fff;
      }
    }
  }
}

.p-answer-form {
  background-color: #fdeef3;

  .cooperated-area {
    border-top: 1px solid #D63864;
    padding-top: 16px;
    margin-top: 16px;
  }
}

.requiredTimeSelect {
  .v-select {
    height: 36px;
    margin-top: 6px;
  }
  .v-field {
    height: 36px;
    margin-top: 0 !important;
  }
  .v-field__field{
    height: 36px;
  }
  .v-field__input{
    padding-top: 6px !important;
  }
}

.v-theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: rgba(0, 0, 0, .12) !important;
  color: rgba(0,0,0,.26) !important;
}

.v-tooltip .v-overlay__content:has(.saveDraftMessage){
  background: #FFFFFF !important;
  border: 1px solid #EF1A65;
  color: #EF1A65 !important;
}
</style>
