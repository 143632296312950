export const ASSIGN_RESERVATIONS = "ASSIGN_RESERVATIONS";
export const ASSIGN_FIRST_RESERVATIONS_OF_DAY = "ASSIGN_FIRST_RESERVATIONS_OF_DAY";
export const ASSIGN_RESERVATIONS_BY_ROOM = "ASSIGN_RESERVATION_BY_ROOM";
export const ASSIGN_RESERVATION = "ASSIGN_RESERVATION";

export const ASSIGN_USERS = "ASSIGN_USERS";
export const ASSIGN_USER = "ASSIGN_USER";
export const ASSIGN_USER_KARTES = "ASSIGN_USER_KARTES";
export const ASSIGN_USER_ANYTIME_CONSULTATIONS = "ASSIGN_USER_ANYTIME_CONSULTATIONS";

export const ASSIGN_CHILD = "ASSIGN_CHILD";

export const ASSIGN_KARTES = "ASSIGN_KARTES";
export const ASSIGN_SIBLING_KARTES = "ASSIGN_SIBLING_KARTES";
export const ASSIGN_KARTE = "ASSIGN_KARTE";
export const ASSIGN_KARTE_IMAGE_LIST = "ASSIGN_KARTE_IMAGE_LIST";
export const ASSIGN_KARTE_REFERENCE_INFO = "ASSIGN_KARTE_REFERENCE_INFO";
export const ASSIGN_KARTE_MEDIA_FILE = "ASSIGN_KARTE_MEDIA_FILE";

export const ASSIGN_SHIFTS = "ASSIGN_SHIFTS";

export const ASSIGN_DOCTORS = "ASSIGN_DOCTORS";
export const ASSIGN_DOCTORS_BY_DEPARTMENT_TYPE = "ASSIGN_DOCTORS_BY_DEPARTMENT_TYPE";

export const ASSIGN_LOGIN_ACTION = "ASSIGN_LOGIN_ACTION";
export const ASSIGN_LOGGED_IN = "ASSIGN_LOGGED_IN";
export const ASSIGN_CURRENT_DOCTOR = "ASSIGN_CURRENT_DOCTOR";
export const EMAIL_LOGIN_ENABLED = "EMAIL_LOGIN_ENABLED";

export const ASSIGN_RELOAD_TIMEOUT_ID = "ASSIGN_RELOAD_TIMEOUT_ID";
export const ASSIGN_RESERVATION_TIMEOUT_ID = "ASSIGN_RESERVATION_TIMEOUT_ID";
export const ASSIGN_PERIOD_LIST_TIMEOUT_ID = "ASSIGN_PERIOD_LIST_TIMEOUT_ID";

export const COUNT_UP_PASSED_SEC = "COUNT_UP_PASSED_SEC";
export const CLEAR_PASSED_SEC = "CLEAR_PASSED_SEC";

export const NOTICE_TIMEOUT_APPROACHING_ALERT = "NOTICE_TIMEOUT_APPROACHING_ALERT";
export const CLEAR_TIMEOUT_APPROACHING_ALERT = "CLEAR_TIMEOUT_APPROACHING_ALERT";

export const ASSIGN_IS_RESET_EMAIL_SENT = "ASSIGN_IS_RESET_EMAIL_SENT";
export const ASSIGN_PASSWORD_RESET_ERROR_DETAIL = "ASSIGN_PASSWORD_RESET_ERROR_DETAIL";
export const ASSIGN_IS_PASSWORD_UPDATED = "ASSIGN_IS_PASSWORD_UPDATED";

export const ASSIGN_ANYTIME_CONSULTATIONS = "ASSIGN_ANYTIME_CONSULTATIONS";
export const ASSIGN_ANYTIME_CONSULTATION = "ASSIGN_ANYTIME_CONSULTATION";
export const ASSIGN_ANYTIME_CONSULTATION_ANSWER = "ASSIGN_ANYTIME_CONSULTATION_ANSWER";
export const ASSIGN_ANYTIME_CONSULTATION_KARTES = "ASSIGN_ANYTIME_CONSULTATION_KARTES";
export const ASSIGN_ANYTIME_CONSULTATION_SIBLING_KARTES = "ASSIGN_ANYTIME_CONSULTATION_SIBLING_KARTES";
export const ASSIGN_ANYTIME_CONSULTATION_REFERENCE_INFO = "ASSIGN_ANYTIME_CONSULTATION_REFERENCE_INFO";
export const ASSIGN_ANYTIME_CONSULTATION_SEARCH_KEYS = "ASSIGN_ANYTIME_CONSULTATION_SEARCH_KEYS";
export const ASSIGN_ANYTIME_CONSULTATION_FILTERED_FLAG = "ASSIGN_ANYTIME_CONSULTATION_FILTERED_FLAG";
export const ASSIGN_ELASTICSEARCH_ERROR_DETAIL = "ASSIGN_ELASTICSEARCH_ERROR_DETAIL";
export const ASSIGN_ANYTIME_CONSULTATION_ANSWER_POPUP_MESSAGE = "ASSIGN_ANYTIME_CONSULTATION_ANSWER_POPUP_MESSAGE";

export const KARTE_UPDATE_RESULT = "KARTE_UPDATE_RESULT";

export const SHIFTS_UPDATE_ERROR_DETAIL = "SHIFTS_UPDATE_ERROR_DETAIL";
export const SHIFTS_UPDATE_SKIPPINGS = "SHIFTS_UPDATE_SKIPPINGS";

export const ASSIGN_RECOMMEND_JOURNALS = "ASSIGN_RECOMMEND_JOURNALS";

export const ASSIGN_EPDS_ANSWER_LIST = "ASSIGN_EPDS_ANSWER_LIST";
export const ASSIGN_EPDS_ANSWER = "ASSIGN_EPDS_ANSWER";
export const ASSIGN_EPDS_URL = "ASSIGN_EPDS_URL";

export const ASSIGN_CHAT_LOGS = "CHAT_LOGS";
export const ADD_CHAT_LOG = "ADD_CHAT_LOG";

export const ASSIGN_ROOM = "ASSIGN_ROOM";

export const ASSIGN_ASSIGNED_ADVICE_SHEETS = "ASSIGN_ASSIGNED_ADVICE_SHEETS";
export const ASSIGN_DAYTIME_ADVICE_SHEET = "ASSIGN_DAYTIME_ADVICE_SHEET";
export const DAYTIME_ADVICE_SHEETS_RESULT = "DAYTIME_ADVICE_SHEETS_RESULT";
export const ASSIGN_ACTIVE_DOCTORS_WITH_SHEETS = "ASSIGN_ACTIVE_DOCTORS_WITH_SHEETS";
export const ASSIGN_DAYTIME_ADVICE_REFERENCE_INFO = "ASSIGN_DAYTIME_ADVICE_REFERENCE_INFO";
export const ASSIGN_DAYTIME_ADVICE_SHEET_STATUSES = "ASSIGN_DAYTIME_ADVICE_SHEET_STATUSES";
export const ASSIGN_DAYTIME_ADVICE_TODAYS_POOLING_COUNT = "ASSIGN_DAYTIME_ADVICE_TODAYS_POOLING_COUNT";
export const ASSIGN_USER_DAYTIME_ADVICE_SHEETS = "ASSIGN_USER_DAYTIME_ADVICE_SHEETS";
export const ASSIGN_DAYTIME_ADVICE_IMAGES = "ASSIGN_DAYTIME_ADVICE_IMAGES";
export const ASSIGN_DAYTIME_ADVICE_TIMEOUT_ID = "ASSIGN_DAYTIME_ADVICE_TIMEOUT_ID";
export const ASSIGN_ASSIGNED_ADVICE_TIMEOUT_ID = "ASSIGN_ASSIGNED_ADVICE_TIMEOUT_ID";
export const ASSIGN_DAYTIME_ADVICE_SEARCH_CONDITION = "ASSIGN_DAYTIME_ADVICE_SEARCH_CONDITION";
export const ASSIGN_AUTO_CALC_CHILD_AGE = "ASSIGN_AUTO_CALC_CHILD_AGE";
export const ASSIGN_DAYTIME_ADVICE_MEDIA_FILE = "ASSIGN_DAYTIME_ADVICE_MEDIA_FILE";

export const ASSIGN_ANYTIME_CONSULTATION_SHIFTS = "ASSIGN_ANYTIME_CONSULTATION_SHIFTS";
export const AC_SHIFTS_UPDATE_ERROR_DETAIL = "AC_SHIFTS_UPDATE_ERROR_DETAIL";
export const AC_SHIFTS_UPDATE_SKIPPINGS = "AC_SHIFTS_UPDATE_SKIPPINGS";
export const ASSIGN_IS_ON_ANYTIME_CONSULTATION_SHIFT = "ASSIGN_IS_ON_ANYTIME_CONSULTATION_SHIFT";
export const ASSIGN_IS_NOTICE_EXPIRATION = "ASSIGN_IS_NOTICE_EXPIRATION";

export const AC_SHIFT_UPDATE_ERROR_DETAIL = "AC_SHIFT_UPDATE_ERROR_DETAIL";

export const ASSIGN_IS_SEARCHING = "ASSIGN_IS_SEARCHING";
