<template>
  <v-container
    fluid
    class="pa-0 pr-4 daytime-advice-sheet-body"
  >
    <v-row
      class="ml-2"
      justify="start"
      align="center"
    >
      <span style="font-size: 14px"> <b>相談方法別の利用可否</b>: </span>
      <div>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useReservation ? 'red' : 'default'"
        >
          <span class="text-black">夜間</span>
          <v-icon
            v-if="daytimeAdviceSheet.useReservation"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useReservation"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useAnytimeConsultation ? 'red' : 'default'"
        >
          <span class="text-black">いつでも</span>
          <v-icon
            v-if="daytimeAdviceSheet.useAnytimeConsultation"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useAnytimeConsultation"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.useDaytimeAdvice ? 'red' : 'default'"
        >
          <span class="text-black">日中助産師</span>
          <v-icon
            v-if="daytimeAdviceSheet.useDaytimeAdvice"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.useDaytimeAdvice"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
      </div>
    </v-row>
    <v-row
      class="ml-2"
      justify="start"
      align="center"
    >
      <span style="font-size: 14px"> <b>相談員種別の利用可否</b>: </span>
      <div>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.pediatricianEnabled ? 'red' : 'default'"
        >
          <span class="text-black">小児科医</span>
          <v-icon
            v-if="daytimeAdviceSheet.pediatricianEnabled"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.pediatricianEnabled"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.obstetricsEnabled ? 'red' : 'default'"
        >
          <span class="text-black">産婦人科医</span>
          <v-icon
            v-if="daytimeAdviceSheet.obstetricsEnabled"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.obstetricsEnabled"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
        <v-chip
          class="ma-1"
          label
          :color="daytimeAdviceSheet.midwifeEnabled ? 'red' : 'default'"
        >
          <span class="text-black">助産師</span>
          <v-icon
            v-if="daytimeAdviceSheet.midwifeEnabled"
            color="rgba(0, 0, 0, .54)"
            size="small"
            end
          >
            mdi-circle-outline
          </v-icon>
          <v-icon
            v-if="!daytimeAdviceSheet.midwifeEnabled"
            size="small"
            end
          >
            mdi-close
          </v-icon>
        </v-chip>
      </div>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        sm="12"
        md="12"
        class="pt-0"
        :class="noAdvice == null ? 'need-choice' : 'choiced'"
      >
        <v-card
          class="advice-radio-group"
          variant="text"
          :class="editing ? 'editing' : 'readonly'"
        >
          <v-label
            class="mb-1"
            :disabled="!editing"
          >
            相談はありましたか？（必須）*
          </v-label>
          <v-radio-group
            v-model="daytimeAdviceSheet.noAdvice"
            :readonly="!editing"
            :disabled="!editing"
            inline
            hide-details
          >
            <v-radio
              label="相談あり"
              :value="false"
            >
              <template v-slot:label>
                <span>相談あり</span>
              </template>
            </v-radio>
            <v-radio
              label="相談なし"
              :value="true"
            >
              <template v-slot:label>
                <span>相談なし</span>
              </template>
            </v-radio>
          </v-radio-group>
        </v-card>
        <daytime-advice-child-age
          v-model:daytimeAdviceSheet="daytimeAdviceSheet"
          :editing="editing"
        />
        <v-textarea
          v-show="!noAdvice"
          id="input-complaint"
          v-model="daytimeAdviceSheet.complaint"
          variant="outlined"
          name="complaint"
          required
          label="主訴（必須）*"
          rows="2"
          :readonly="!editing || noAdvice != false"
          :disabled="!editing || noAdvice != false"
          class="pt-1 pb-0"
        />
        <midwife-diagnosis
          v-if="daytimeAdviceSheet.departmentType === 3"
          v-show="!noAdvice"
          v-model:karte="daytimeAdviceSheet"
          :active="editing"
          :no-advice="noAdvice"
        />
        <v-select
          v-show="!noAdvice"
          v-model="daytimeAdviceSheet.outcome"
          variant="outlined"
          name="outcome"
          :items="outcomeOptions"
          item-title="key"
          item-value="value"
          required
          :readonly="!editing || noAdvice != false"
          :disabled="!editing || noAdvice != false"
          label="転帰（必須）*"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import outcomeOptions from "../../mixins/outcome_options.js";
import MidwifeDiagnosis from "./MidwifeDiagnosis.vue";
import DaytimeAdviceChildAge from "./DaytimeAdviceChildAge.vue";

export default {
  name: "DaytimeAdviceSheetBody",
  components: {
    MidwifeDiagnosis,
    DaytimeAdviceChildAge,
  },
  mixins: [outcomeOptions],
  props: {
    editing: Boolean,
  },
  computed: {
    ...mapGetters(["daytimeAdviceSheet"]),
    noAdvice: function () {
      return this.daytimeAdviceSheet.noAdvice;
    },
  },
};
</script>

<style lang="scss">
.daytime-advice-sheet-body {
  /* テキストエリア/セレクトボックス ラベル色 */
  div.v-input--disabled.v-text-field .v-text-field__field label {
    color: #c0c0c0 !important;
  }

  .v-field-label {
    color: black;
  }

  /* テキストエリア/セレクトボックス 枠 */
  .v-text-field--outlined:not(.v-input--is-focused) fieldset,
  .v-text-field--outlined:not(.v-input--is-focused)
    .v-input__slot:hover
    fieldset {
    border: 2px solid #c0c0c0 !important;
  }

  /* 保存された入力文字 */
  /* vuetify3 対応 入力不可のラベルの透明度を1にする */
  .v-slection-control--disabled,
  .v-field--disabled {
    --v-disabled-opacity: 1 !important;
  }

  /* 編集エリアの背景色 */
  div.v-input.v-text-field:not(.v-input--disabled) .v-input__slot,
  div.v-input.v-text-field:not(.v-input--disabled) .v-field__field,
  div.v-input.v-text-field:not(.v-input--disabled) .v-field__overlay {
    background-color: #e8f2ff !important;
  }

  /* readonlyのテキストエリア背景色 */
  div.v-input.v-text-field.v-input--readonly.v-textarea .v-field__field,
  div.v-input.v-text-field.v-input--readonly.v-select .v-field__field,
  div.v-input.v-text-field.v-input--readonly.v-select .v-field__overlay {
    background: transparent !important;
  }

  /* 暫定診断の特別措置 */
  .diagnosis-form
    div.v-input.v-text-field.v-input--readonly.v-textarea
    .v-field__field {
    background-color: #e8f2ff !important;
  }

  .diagnosis-form
    div.v-input.v-text-field.v-input--readonly.v-input--disabled.v-textarea
    .v-field__field {
    background: transparent !important;
  }

  /** チャット入力欄の背景色が上記スタイルに影響されるため強制的に白に */
  #chat-input div.v-input.v-text-field:not(.v-input--readonly) .v-input__slot {
    background-color: #fff !important;
  }

  /* テキストボックスの入力位置 */
  .v-text-field__slot input#child_age {
    margin-top: 15px !important;
  }

  /* 相談はありましたか？（必須）ラジオボタン */
  div.advice-radio-group {
    margin-top: 24px !important;
    margin-bottom: 24px !important;
    padding: 16px !important;
    border: 2px solid #c0c0c0 !important;
    border-radius: 4px !important;

    .v-label {
      font-size: 16px !important;
      font-weight: bold !important;
    }

    .v-radio {
      padding: 12px 42px;
      border: 1px solid #c0c0c0 !important;
      border-radius: 6px !important;
    }

    .v-selection-control-group--inline {
      justify-content: space-between;
    }

    .v-selection-control--inline {
      width: 49%;
    }
  }

  /* 編集中 */
  div.advice-radio-group.editing {
    .v-label {
      opacity: 1 !important;
    }
  }

  /* 選択が必要な状態 */
  div.need-choice {
    opacity: 1 !important;

    /* 編集中 */
    div.advice-radio-group.editing {
      background-color: #e8f2ff !important;

      .v-selection-control--inline {
        background-color: #fff !important;
      }
    }

    /* 編集してない */
    div.advice-radio-group.readonly {
      background: transparent;
    }
  }

  /* 選択済の状態 */
  div.choiced {
    div.advice-radio-group {
      .v-selection-control--inline .v-label {
        --v-medium-emphasis-opacity: 0.87 !important;
      }

      .v-selection-control--inline.v-selection-control--dirty {
        background-color: rgb(252, 228, 236, 1) !important;
        opacity: 0.87;

        .mdi-radiobox-marked {
          color: #ec407a !important;
        }
      }

      .v-selection-control--inline:not(.v-selection-control--dirty) {
        .v-label {
          opacity: 0.5 !important;
        }
      }
    }

    /* 編集中 */
    div.advice-radio-group.editing {
      background-color: #fff !important;
    }
  }

  /* 相談なしチェックボックスラベルの位置調整 */
  .v-label.v-label--clickable {
    margin-bottom: 0px !important;
  }
}
</style>
