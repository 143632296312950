import axios from "./index";

export default {
  fetchAnytimeConsultations(callback, params) {
    axios.get("/api/v1/anytime_consultations", {
      params: {
        department: params.department,
        complete: params.complete
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchAssignedAnytimeConsultations(callback, params) {
    axios.get("/api/v1/anytime_consultations/assigned", {
      params: {
        department: params.department,
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchCompletedAnytimeConsultations(callback, params) {
    axios.get("/api/v1/anytime_consultations/completed", {
      params: {
        department: params.department,
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  searchAnytimeConsultations(callback, params) {
    axios.get("/api/v1/anytime_consultations/search/", {
      params: {
        q: {
          anytime_consultation_answer_doctor_family_name_cont: params.doctor_family_name,
          anytime_consultation_answer_doctor_first_name_cont: params.doctor_first_name,
        },
        department_types: params.department_types,
        icd10_keyword: params.icd10_keyword,
        answer_keyword_search_rule: params.answer_keyword_search_rule,
        answer_keywords: params.answer_keywords,
        child_sex_eq_any: params.child_genders,
        child_age_ranges: params.child_age_ranges,
        force_all: params.force_all, // すべての結果を返す（デフォルト100件のみ）
        // For datadog
        department_type: params.department_types,
        doctor_family_name: params.doctor_family_name,
        doctor_first_name: params.doctor_first_name,
        answer_keyword: params.answer_keywords
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  elasticsearchAnytimeConsultations(params, successCallback, failureCallback) {
    axios.post("/api/v1/anytime_consultations/elasticsearch/", {
      id: params.id,
      query: params.query
    }).then(response => {
      successCallback(response.data);
    }).catch(error => {
      failureCallback(error);
    });
  },

  findAnytimeConsultation(callback, params) {
    axios.get("/api/v1/anytime_consultations/" + params.id, {

    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  findAnytimeConsultationAnswer(callback, params) {
    axios.get("/api/v1/anytime_consultation_answers/" + params.anytime_consultation_id, {

    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  saveAnytimeConsultationAnswerDraft(params, successCallback, failureCallback) {
    axios.post("/api/v1/anytime_consultation_answers/save_draft", {
      anytime_consultation_answer: {
        anytime_consultation_id: params.anytime_consultation_id,
        content: params.content,
        icd10_branch_id: params.icd10_branch_id,
        icd10_original_disease: params.icd10_original_disease,
        complaint: params.complaint,
        required_time: params.required_time
      }
    }, {
      headers: {"Content-Type": "application/json"}
    }).then(response => {
      successCallback(response.data);
    }).catch(error => {
      failureCallback(error);
    });
  },

  completeAnytimeConsultationAnswer(params, successCallback, failureCallback) {
    axios.post("/api/v1/anytime_consultation_answers/complete", {
      anytime_consultation_answer: {
        anytime_consultation_id: params.anytime_consultation_id,
        content: params.content,
        icd10_branch_id: params.icd10_branch_id,
        icd10_original_disease: params.icd10_original_disease,
        complaint: params.complaint,
        required_time: params.required_time
      }
    }, {
      headers: {"Content-Type": "application/json"}
    }).then(response => {
      successCallback(response.data);
    }).catch(error => {
      failureCallback(error);
    });
  },

  cancelAnytimeConsultationAnswer(params, successCallback, failureCallback) {
    axios.post("/api/v1/anytime_consultation_answers/cancel", {
      anytime_consultation_answer: {
        anytime_consultation_id: params.anytime_consultation_id,
      }
    }, {
      headers: {"Content-Type": "application/json"}
    }).then(response => {
      successCallback(response.data);
    }).catch(error => {
      failureCallback(error);
    });
  },

  fetchAnytimeConsultationKartes(callback, params) {
    axios.get("/api/v1/anytime_consultations/kartes/" + params.id, {
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  fetchAnytimeConsultationSiblingKartes(callback, params) {
    axios.get("/api/v1/anytime_consultations/sibling_kartes/" + params.id, {
      cancelToken: params.cancelToken
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  findAnytimeConsultationReferenceInfo(callback, params) {
    axios.get("/api/v1/anytime_consultation_reference_info", {
      params: {
        anytime_consultation_id: params.anytime_consultation_id,
      }
    }).then(response => {
      callback(response.data);
    }).catch(error => {
      console.log(error);
    });
  },

  deleteAnytimeConsultationAnswer(params, successCallback, failureCallback) {
    axios.delete("/api/v1/anytime_consultation_answers/" + params.anytime_consultation_id, {

    }).then(response => {
      successCallback(response.data);
    }).catch(error => {
      failureCallback(error);
    });
  },
};
