<template>
  <v-row
    noguide
    class="anytime-consultation-info-detail"
  >
    <v-col
      cols="3"
      class="py-0"
    >
      <v-row
        class="full-height my-0"
        noguide
      >
        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card
            d-flex
            :flat="true"
            class="px-2 d-flex align-center"
          >
            <v-btn
              :to="{ name: 'anytimeConsultation', params: { id: anytimeConsultation.id}, query: { tab_id: tabId }}"
              block
              class="ml-0"
              color="#3ACAC9"
            >
              <span class="text-white">詳細</span>
            </v-btn>
          </v-card>
        </v-col>
        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card :flat="true">
            <v-card-text
              :class="currentDoctor.id === anytimeConsultation.assignDoctorId ? 'pink--text' : ''"
            >
              <span v-text="anytimeConsultation.assignDoctorName" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card :flat="true">
            <v-card-text>
              <span v-text="anytimeConsultation.id" />
            </v-card-text>
          </v-card>
        </v-col>

        <v-col
          cols="3"
          class="pa-0"
        >
          <v-card :flat="true">
            <v-card-text>
              <span v-text="anytimeConsultation.status" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card :flat="true">
        <v-card-text>
          <div>
            <template v-if="incentive">
              <v-chip
                label
                class="mb-2"
                color="pink"
                size="small">
                <v-icon>mdi-alert-circle-outline</v-icon>
                <span class="ms-1" style="font-size: 11px">必ず優先的に回答してください</span></v-chip>
              <br>
            </template>
            <span><b>質問日時</b>： {{ anytimeConsultation.askedAt }}</span>
            <br>
            <span><b>締切日時</b>： {{ anytimeConsultation.deadlineAt }}</span>
            <template v-if="anytimeConsultation.overDeadline">
              <br>
              <span style="font-size: 10px; color: gray">※他の相談員が回答しなかったため、締切を延長</span>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card :flat="true">
        <v-card-text>
          <div>
            <span><b>回答者</b>： {{ anytimeConsultation.doctorName }}</span>
            <br>
            <span class="oneline-ellipsis"><b>主訴</b>： {{ anytimeConsultation.complaint }}</span>
            <span><b>暫定診断</b>： {{ anytimeConsultation.icd10BranchName }}</span>
            <br>

            <template v-if="anytimeConsultation.department_type == 'pediatrician'">
              <child-full-property
                :object="anytimeConsultation"
                :is-child-name-displayable="false"
              />
            </template>
            <template v-else>
              <span><b>相談対象者</b>： {{ anytimeConsultation.userBirthdate }}｜{{ anytimeConsultation.userGender }}</span>
              <br>
              <div v-if="anytimeConsultation.inPregnancy == 'pregnant'">
                <span><b>出産予定日</b>： {{ anytimeConsultation.lastDueDate }} ({{
                  anytimeConsultation.childAge
                }})</span>
                <br>
              </div>
              <div v-else-if="anytimeConsultation.inPregnancy == 'postpartum'">
                <span><b>最後の出産日</b>： {{ anytimeConsultation.lastDueDate }} ({{
                  anytimeConsultation.childAge
                }})</span>
                <br>
              </div>
              <div v-else-if="anytimeConsultation.inPregnancy == 'others'" />

              <span><b>妊娠回数</b>： {{ anytimeConsultation.pastPregnancyNum }}</span> |
              <span><b>出産回数</b>： {{ anytimeConsultation.pastDeliveryNum }}</span>
            </template>
            <br>
            <span v-if="anytimeConsultation.needAttentionUser">
              <v-chip
                class="ma-1"
                label
                color="red"
              >
                <v-icon
                  color="rgba(233, 30, 99, 1)"
                  size="small"
                >
                  mdi-alert-circle-outline
                </v-icon>
                <span class="text-pink">運営特記事項あり</span>
              </v-chip>
            </span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col
      cols="3"
      class="pa-0"
    >
      <v-card :flat="true">
        <v-card-text>
          <div>
            <template v-if="anytimeConsultation.department_type == 'pediatrician'">
              <span><b>子どもID</b>： {{ anytimeConsultation.childId }}</span>
              <br>
            </template>
            <span><b>ユーザID</b>： {{ anytimeConsultation.userId }}</span>
            <br>
            <span><b>クーポン名</b>： {{ anytimeConsultation.userCouponName }}</span>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import ChildFullProperty from "./ChildFullProperty.vue";

export default {
  name: "AnytimeConsultationCard",
  components: {
    ChildFullProperty
  },
  props: ["anytimeConsultation", "tabId", "incentive"],
  computed: {
    ...mapGetters([
      "currentDoctor",
    ]),
  },
};
</script>

<style lang="scss">
  .anytime-consultation-info {
    .anytime-consultation-info-detail {
      margin: 6px;

      .full-height {
        min-height: 100%;
        height: 100%;
      }

      .oneline-ellipsis {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
</style>
